import ApiService from '@/services/core/api.service'

const ReplenishmentService = {
  async get (query = '') {
    return ApiService.get(`disbursement-associate/reports/revolving-replenishment?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`disbursement-associate/exports/revolving-replenishment?${query}`)
  }
}

export default ReplenishmentService
