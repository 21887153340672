import ApiService from '@/services/core/api.service'

const ItemCenterService = {
  async get (query = '') {
    return ApiService.get(`auditor/item-centers?${query}`)
  },

  async show (itemCenter, query = '') {
    return ApiService.get(`auditor/item-centers/${itemCenter}?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`auditor/exports/item-centers?${query}`)
  }
}

export default ItemCenterService
